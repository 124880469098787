import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (msg) => {
    notification.success({
        message: msg,
    });
};

const state = () => ({
    order: {
        type: 'SHOP',
        relation: {type: 'CONSUMER'},
        relationID: null,
        signatureType: 'ESIGNATURE',
        signatureData: null,
        flowQuestions: [],
        subProducts: [],
        productID: null,
        subType: null,
        key: 0,
        discountPercentage: 0,
        extraData: {
            batteryCapacity: 0,
            batteryPrice: 0,
            userKnowsConsumption: true,
            meterCupboard: 1,
            capacityCode: '',
            tariff: {
                old: {
                    electricity: 0.31,
                    gas: 1.34,
                    returnElectricity: 0.11,
                },
                new: {
                    electricity: 0.19,
                    gas: 0.57,
                    returnElectricity: 0.122,
                }
            },
            usage: {
                electricity: null,
                returnElectricity: null,
                gas: null,
            },
            estimation: {
                buildingType: null,
                persons: null,
                hasChargingStation: false,
                chargingStationLoadingSessions: 0,
                hasSolarPanels: false,
                amountOfSolarPanels: 0,
            },
            savings: {
                paybackPeriod: null,
                totalSavings: null,
            }
        },
    },
    subProducts: {
        data: [],
        total: 0,
        key: null,
    },
    subProduct: {
        id: null,
        name: null,
        active: null,
        hasAutoSync: null,
        imageFileURL: null,
        description: '',
        externalReference: null,
        amount: null,
        subCategoryID: null,
    },
    productsSubProducts: {
        data: [],
        total: 0,
        key: null,
        currentPage: 1,
        from: 0,
        to: 0,
        pageSize: 10,
    },
    orderSubProducts: {
        data: [],
        key: null,
        total: 0,
    },
    currentPageProduct: 1,
    productCount: 0,
    fromProduct: 0,
    toProduct: 0,
    cart: [],
    products: [],
    categories: [],
    shopQuestions: [],
    productSubCategories: [],
    loadingCreateOrder: false,
    errorCreateOrder: false,
    errorFetchShopQuestions: false,
    loadingFetchShopQuestions: false,
    errorPatchShopQuestions: false,
    loadingPatchShopQuestions: false,
    errorFetchShopProducts: false,
    loadingFetchShopProducts: true,
    errorPreFillRelationOnShopOrder: false,
    loadingPreFillRelationOnShopOrder: false,
    loadingFetchShopCategories: false,
    errorFetchShopCategories: false,
    errorOrderSubProducts: false,
    loadingOrderSubProducts: false,
    loadingFetchShopFlowQuestions: false,
    errorFetchShopFlowQuestions: false,
    loadingFetchShopSubProducts: false,
    errorFetchShopSubProducts: false,
    errorShopSubProduct: false,
    loadingShopSubProduct: false,
    loadingPatchShopSubProduct: false,
    errorPatchShopSubProduct: false,
    loadingShopSubCategories: false,
    errorShopSubCategories: false,
    loadingSyncShopProducts: false,
    errorSyncShopProducts: false,
    productFilters: {
        categoryID: null,
        priceFrom: 0,
        priceTill: 20000,
        q: null,
    }
});

const actions = {
    async setLeadAreaTimeBlockID({commit}, data) {
        await commit('setLeadAreaTimeBlockID', data);
    },
    async setOrderSubTypeShop({commit}, type) {
        await commit('setOrderSubTypeShop', type);
    },
    async setOrderFlowShop({commit}, flow) {
        await commit('setOrderFlowShop', flow);
    },
    async setSignatureShopData({commit}, flow) {
        await commit('setSignatureShopData', flow);
    },
    async addRelationToShopOrder({commit}, data) {
        await commit('addRelationToShopOrder', data.value);
        await data.stepRoute();
    },
    async preFillRelationOnShopOrder({commit}, data) {
        try {
            await commit('preFillRelationOnShopOrderBegin');
            await commit('addRelationToShopOrder', data.value);
            await commit('preFillRelationData', data.value)
            await commit('preFillRelationOnShopOrderSuccess');
            await data.stepRoute();
        } catch (err) {
            await commit('preFillRelationOnShopOrderErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async createShopOrder({commit, dispatch, state}, data) {
        try {
            await commit('orderDataShopCreateBegin');
            await commit('addSubProductOnOrder');
            const order = JSON.parse(JSON.stringify(state.order));
            order.productID = data.productID;
            order.subProducts = data.subProducts;
            // order.subProducts = [...order.subProducts, ...data.addons];
            await DataService.post(`/orders`, order);
            await commit('orderDataShopCreateSuccess');
            await dispatch('setCurrentPageOrder', 1);
            await data.successRoute();
            await commit('resetShopOrder');
        } catch (err) {
            await commit('orderDataShopCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async createShopWholeSaleOrder({commit, dispatch, state}, data) {
        try {
            await commit('orderDataShopCreateBegin');
            await commit('addSubProductOnOrder');
            const order = JSON.parse(JSON.stringify(state.order));
            order.productID = data.productID;
            order.subProducts = state.subProducts.data;
            await DataService.post(`/orders`, order);
            await commit('orderDataShopCreateSuccess');
            await dispatch('setCurrentPageOrder', 1);
            await data.successRoute();
            await commit('resetShopOrder');
        } catch (err) {
            await commit('orderDataShopCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async createLeadOrder({commit, dispatch, state}, data) {
        try {
            await commit('orderDataShopCreateBegin');
            const order = JSON.parse(JSON.stringify(state.order));
            order.productID = data.productID;
            await DataService.post(`/orders`, order);
            await commit('orderDataShopCreateSuccess');
            await dispatch('setCurrentPageOrder', 1);
            await data.successRoute();
            await commit('resetShopOrder');
        } catch (err) {
            await commit('orderDataShopCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async resetShopOrder({commit}) {
        await commit('resetShopOrder');
    },
    async resetRelationShopOrder({commit}) {
        await commit('preFillRelationData', {
            id: null,
            createTimestamp: null,
            modifiedTimestamp: null,
            deletedTimestamp: null,
            isAnonymized: null,
            organizationID: null,
            externalReference: null,
            type: 'CONSUMER',
            isParent: null,
            parentID: null,
            firstName: null,
            middleName: null,
            lastName: null,
            gender: 'MALE',
            birthDate: null,
            phoneNumber: null,
            mobileNumber: null,
            email: null,
            street: null,
            houseNumber: null,
            houseNumberAddition: null,
            postalCode: null,
            city: null,
            country: null,
            companyName: null,
            companyCocNumber: null,
            bankAccountType: null,
            bankAccountNumber: null,
            debtorName: null,
            saveToRevision: false,
        })
        await commit('resetRelationShopOrder');
    },
    async fetchShopProducts({commit}, data) {
        try {
            await commit('fetchShopProductsBegin');
            const response = await DataService.get(`offerflow/shop/products`, data);
            await commit('fetchShopProductsSuccess', response.data);
        } catch (err) {
            await commit('fetchShopProductsErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async fetchShopSub1Products({commit}, data) {
        try {
            await commit('fetchShopProductsBegin');
            const response = await DataService.get('offerflow/shop/products?productID=215&categoryID=2', data);
            await commit('fetchShopProductsSuccess', response.data);
        } catch (err) {
            await commit('fetchShopProductsErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },

    async fetchShopSubProducts({commit}, data) {
        try {
            await commit('fetchShopSubProductsBegin');
            const response = await DataService.get(`products/${data.id}/subproducts`, data);
            await commit('fetchShopSubProductsSuccess', response.data);
        } catch (err) {
            await commit('fetchShopSubProductsErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async createShopSubProducts({commit}, data) {
        try {
            await commit('shopSubProductDataCreateBegin');
            await DataService.post(`products/${data.value.shopId}/subproducts`, data.value);
            data.close();
            await commit('shopSubProductDataCreateSuccess');
        } catch (err) {
            await commit('shopSubProductDataCreateErr', err);
            data.close();
            addNotificationError(err?.response?.data?.message);
        }
    },
    async fetchShopCategories({commit}, data) {
        try {
            await commit('fetchShopCategoriesBegin');
            const response = await DataService.get(`offerflow/shop/categories`, data);
            await commit('fetchShopCategoriesSuccess', response.data);
        } catch (err) {
            await commit('fetchShopCategoriesErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async fetchShopFlowQuestions({commit}, data) {
        try {
            await commit('fetchShopFlowQuestionsBegin');
            const response = await DataService.get('offerflow/shop/flowquestions', data);
            await commit('fetchShopFlowQuestionsSuccess', response.data);
        } catch (err) {
            await commit('fetchShopFlowQuestionsErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async setProductOnShopOrder({commit}, data) {
        await commit('setProductOnShopOrder', data);
        await data.stepRoute();
    },
    setProductFilters({commit}, data) {
        commit('setProductFilters', data);
    },
    async setProductQuantity({commit}, data) {
        await commit('setProductQuantity', data);
    },
    async setSubProductQuantity({commit}, data) {
        await commit('setSubProductQuantity', data);
        await commit('calculateSubProductTotal')
    },
    async removeSubProduct({commit}, index) {
        await commit('removeSubProduct', index);
        await commit('calculateSubProductTotal')
    },
    async setShopSubProductsCurrentPage({commit}, page) {
        await commit('setShopSubProductsCurrentPage', page);
    },
    async handleBatteryDiscount({commit}, data) {
        await commit('handleBatteryDiscount', data);
    },
    async addProductToCard({commit}, data) {
        try {
            await commit('addProductToCard', data);
            await commit('calculateSubProductTotal')
            if (data.showNotification) {
                await addNotificationSuccess('Product is toegevoegd aan winkelmandje');
            }
        } catch (err) {
            addNotificationError('Fout opgetreden bij het toevoegen van het product aan winkelmandje');
        }
    },
    orderCreateShopValidationError() {
        addNotificationError('Vul alle benodigde informatie in.');
    },
    async getOrderSubProducts({commit}, id) {
        try {
            await commit('orderSubProductsDataBegin');
            const response = await DataService.get(`/orders/${id}/subproducts`);
            commit('orderSubProductsDataSuccess', response.data);
        } catch (err) {
            await commit('orderSubProductsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async getShopSubProduct({commit}, data) {
        try {
            await commit('getShopSubProductDataBegin');
            const response = await DataService.get(`/products/${data.shopId}/subproducts/${data.id}`);
            commit('getShopSubProductDataSuccess', response.data);
        } catch (err) {
            await commit('getShopSubProductDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async getShopSubCategories({commit}, shopId) {
        try {
            await commit('getShopSubCategoriesDataBegin');
            const response = await DataService.get(`/products/${shopId}/subcategories`, {pageSize: 1000});
            commit('getShopSubCategoriesDataSuccess', response.data.data);
        } catch (err) {
            await commit('getShopSubCategoriesDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async patchShopSubProduct({commit}, data) {
        try {
            await commit('shopSubProductDataPatchBegin');
            await DataService.patch(`/products/${data.value.shopId}/subproducts/${data.value.id}`, data.value);
            await commit('shopSubProductDataPatchSuccess');
            data.close();
            addNotificationSuccess('Product is aangepast.');
        } catch (err) {
            await commit('shopSubProductDataPatchErr', err);
            data.close();
            addNotificationError(err?.response?.data?.message);
        }
    },
    async resetShopSubProduct({commit}) {
        commit('resetShopSubProduct');
    },
    async syncShopProducts({commit}, shopId) {
        try {
            await commit('syncShopProductsDataBegin');
            await DataService.post(`/products/${shopId}/syncsubproducts`);
            addNotificationSuccess('Product synchronization met succes getriggerd.');
        } catch (err) {
            await commit('syncShopProductsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async setSelectedPersons({commit}, data) {
        commit('orderSetSelectedPersons', data);
    },
    async batterySavingsCalculation({commit}, data) {
        commit('batterySavingsCalculation', data);
    },
    async batterySavingsCalculationMKB({commit}, data) {
        commit('batterySavingsCalculationMKB', data);
    },
    async setOrderExtraDataUsage({commit}, data) {
        commit('setOrderExtraDataUsage', data);
    },
    async setAmountOfSolarPanels({commit}, data) {
        commit('setAmountOfSolarPanels', data);
    },
    async setAmountChargingStationLoadingSessions({commit}, data) {
        commit('setAmountChargingStationLoadingSessions', data);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
